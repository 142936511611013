// @import url('https://fonts.googleapis.com/css?family=Marcellus+SC|Red+Hat+Display&display=swap');

// Override default variables before the import
//$body-bg: #000;
// $primary: deepskyblue;
// $secondary:#ccc;
//$font-family-base: 'Marcellus SC', serif;
//$font-family-base: 'Red Hat Display', sans-serif;

// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';

$primary: #11b9c2 !default;
@import "~bootswatch/dist/materia/variables";
@import "~bootstrap/scss/bootstrap";

@import "~bootswatch/dist/materia/bootswatch";

@import '~ol/ol.css';

@import "~react-datepicker/dist/react-datepicker.css";

@import 'react-big-calendar/lib/sass/styles';


.classname-applied-to-content div:nth-child(2){
    z-index: 0 !important;
}

.bg-selected {
    background-color: darken($primary, 20%) !important;
}

.bg-grey  {
    background-color: #eee !important;
}

.pointer {
    cursor: pointer;
}


.with-sticky-footer {
    padding-bottom: 54px;
}

.sticky-footer {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
}


.sticky-header {
    top: 0;
    min-height: 54px;
    width: 100%;
    position: sticky;
    z-index: 1000;
}


.modal-content {
    border-radius: 6px;
}


.filter-label {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
